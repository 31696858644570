import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import { FiPause, FiPlay } from 'react-icons/fi';
import { MdReplay } from 'react-icons/md';
import { ImSpinner2 } from 'react-icons/im'; // Иконка для индикатора загрузки

const Player = ({ videoLink, startTime, endTime }) => {
    const [playing, setPlaying] = useState(false);
    const [pauseButton, setPauseButton] = useState(false);
    const [isOverTime, setIsOverTime] = useState(false);
    const [loading, setLoading] = useState(true); // Состояние для отслеживания загрузки видео
    const playerRef = useRef(null);

    const isYoutube = !(videoLink.startsWith("https://storage.googleapis.com/") ||
        videoLink.startsWith("https://firebasestorage.googleapis.com/"));

    const canPlay = ReactPlayer.canPlay(videoLink);

    const handleProgress = (state) => {
        if (!isOverTime && endTime === Math.floor(state.playedSeconds) && playing) {
            setPlaying(false);
            setIsOverTime(true);
        }
    };

    const handleStartTime = () => {
        if (playerRef.current) {
            playerRef.current.seekTo(startTime, 'seconds');
            setPlaying(true);
            setIsOverTime(false);
        }
    };

    const handlePlayPause = () => {
        setPlaying((prev) => !prev);
    };

    useEffect(() => {
        if (!playing) {
            setPauseButton(false);
        } else {
            setPauseButton(true);
        }
    }, [playing]);

    const handleReady = () => {
        setLoading(false); // Скрыть индикатор загрузки, когда видео готово к воспроизведению
    };

    const handleBuffer = () => {
        setLoading(true); // Показать индикатор загрузки, если видео буферизуется
    };

    return (
        <React.Fragment>
            {canPlay ? (
                <div className="w-full aspect-video">
                    <div className="player-wrapper mb-5 relative">
                        {loading && (
                            <div className={`${!isYoutube ? 'z-50' : '' } flex flex-row absolute inset-0 items-center justify-center rounded-[20px] bg-[#cbd5e0]`}>
                                <ImSpinner2 className="animate-spin text-white w-10 h-10"/>
                            </div>
                        )}
                        <ReactPlayer
                            ref={playerRef}
                            width="100%"
                            height="100%"
                            className={`react-player ${loading && !isYoutube ? 'z-40' : '' }`}
                            onProgress={handleProgress}
                            onStart={() => setPauseButton(true)}
                            onPlay={() => setPauseButton(true)}
                            onPause={() => setPauseButton(false)}
                            onEnded={() => setPauseButton(false)}
                            playing={playing}
                            url={videoLink}
                            playsinline
                            controls
                            onReady={handleReady}
                            onBuffer={handleBuffer}
                            // onBufferEnd={handleBufferEnd}
                            onError={(e) => console.log('onError', e)}
                            config={{
                                youtube: {
                                    playerVars: {
                                        showinfo: 0,
                                        cc_load_policy: 0,
                                        start: startTime,
                                    },
                                },
                            }}
                        />
                    </div>
                    <div className="flex flex-row justify-between">
                        <button
                            onClick={handlePlayPause}
                            className="btn btn-sm rounded-full shadow-brand bg-white border-white border-2 focus:border-white hover:border-white focus:bg-white hover:bg-white"
                        >
                            {pauseButton ? (
                                <FiPause className="w-6 h-6 stroke-current text-primary" />
                            ) : (
                                <FiPlay className="w-6 h-6 stroke-current text-primary" />
                            )}
                        </button>
                        <button
                            onClick={handleStartTime}
                            className="btn btn-sm shadow-brand bg-white rounded-full btn-wide border-white border-2 focus:border-white hover:border-white focus:bg-white hover:bg-white"
                        >
                            <MdReplay className="w-6 h-6 stroke-current mr-1 text-primary" />
                            <span className="text-primary">Play again</span>
                        </button>
                    </div>
                </div>
            ) : (
                <div className="w-full aspect-video self-center">
                    <svg
                        width="100%"
                        height="100%"
                        style={{ borderRadius: '0.75rem', overflow: 'hidden' }}
                        className="react-player"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1920 1080"
                    >
                        <rect fill="#cbd5e0" width="1920" height="1080" />
                    </svg>
                </div>
            )}
        </React.Fragment>
    );
};

Player.propTypes = {
    videoLink: PropTypes.string,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
};

const mapStateToProps = (state) => ({
    videoLink: state.config.videoLink,
    startTime: state.config.startTime,
    endTime: state.config.endTime,
});

export default connect(mapStateToProps)(Player);